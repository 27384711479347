<template>
  <layout-default>
    <v-layout wrap>
      <v-flex xs12 class="mb-3">
        <v-card>
          <v-card-title>
            Users
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :loading="$apollo.loading"
              :headers="headers"
              :items="users"
              :search="search"
          >
            <template v-slot:item="{ item }">
              <tr :active="item.selected">
                <td class="text-xs-left">{{ item.id }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.email }}</td>
                <td class="text-xs-left">
                  {{ item.active ? "Yes" : "No" }}
                </td>
                <td class="text-xs-left">
                  <template>
                    <v-select
                        :disabled="
                      item.id === user.id || user.role !== ADMIN_ROLE
                    "
                        v-model="item.role"
                        :items="options"
                        item-text="label"
                        item-value="value"
                        @change="setRole(item.id, $event)"
                    >
                    </v-select>
                  </template>
                </td>
                <td class="text-xs-right">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-title
                              v-if="user.role === ADMIN_ROLE"
                              @click="
                          $router.push({
                            name: 'setUserPassword',
                            params: { userId: item.id },
                          })
                        "
                          >
                            Change user password
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="
                        item.active === true && item.id !== user.id
                      "
                        >
                          <v-list-item-title
                              v-if="user.role === ADMIN_ROLE"
                              @click="deactivateUser(item.id)"
                          >
                            Deactivate
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="
                        item.active === false && item.id !== user.id
                      "
                        >
                          <v-list-item-title
                              v-if="user.role === ADMIN_ROLE"
                              @click="activateUser(item.id)"
                          >
                            Activate
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-alert
              v-if="lastError"
              :value="true"
              color="error"
              icon="fa-error"
          >{{ lastError }}
          </v-alert
          >
        </v-card>
      </v-flex>
    </v-layout>
  </layout-default>
</template>

<script>
import USERS_LIST from "@/graphql/query/UsersList.gql";
import LayoutDefault from "@/layouts/Default.vue";
import CURRENT_USER from "@/graphql/query/CurrentUser.gql";
import ACTIVATE_USER from "@/graphql/mutation/ActivateUserMutation.gql";
import DEACTIVATE_USER from "@/graphql/mutation/DeactivateUserMutation.gql";
import SET_USER_ROLE from "@/graphql/mutation/SetUserRoleMutation.gql";

export default {
  data: () => ({
    user: null,
    ADMIN_ROLE: "ADMIN",
    users: [],
    search: "",
    options: [
      {value: "ADMIN", label: "ADMIN"},
      {value: "USER", label: "USER"},
      {value: "READONLY", label: "READONLY"},
    ],
    headers: [
      {text: "Id", align: "left", value: "id"},
      {text: "Name", align: "left", value: "name"},
      {text: "Email", align: "left", value: "email"},
      {text: "Active", align: "left", value: "active"},
      {text: "Role", align: "left", value: "role"},
      {text: "Action", align: "left", value: "action"},
    ],
    lastError: null,
    hasErrors: false,
    loading: false,
  }),
  components: {
    LayoutDefault,
  },
  apollo: {
    users: {
      query: USERS_LIST,
    },
    user: {
      query: CURRENT_USER,
    },
  },
  methods: {
    async setRole(userId, role) {
      const variables = {
        userId,
        role,
      };
      this.$apollo
          .mutate({
            mutation: SET_USER_ROLE,
            variables,
            update: (store, {data: {setUserRole}, errors}) => {
              this.loading = false;
              if (errors) {
                return;
              }

              const data = store.readQuery({
                query: USERS_LIST,
              });
              const modifiedUser = data.users.find(
                  (u) => u.id === setUserRole.id
              );
              modifiedUser.role = role;
              store.writeQuery({
                query: USERS_LIST,
                data,
              });
            },
          })
          .catch((error) => {
            this.hasErrors = true;
            this.loading = false;
            this.lastError = error;
          });
    },
    async activateUser(userId) {
      const variables = {
        userId: userId,
      };
      this.loading = true;

      this.$apollo
          .mutate({
            mutation: ACTIVATE_USER,
            variables,
            update: (store, {data: {activateUser}, errors}) => {
              this.loading = false;
              if (errors) {
                return;
              }
              const data = store.readQuery({
                query: USERS_LIST,
              });
              const modifiedUser = data.users.find(
                  (u) => u.id === activateUser.id
              );
              modifiedUser.active = true;
              store.writeQuery({
                query: USERS_LIST,
                data,
              });
            },
          })
          .catch((error) => {
            this.hasErrors = true;
            this.loading = false;
            this.lastError = error;
          });
    },
    async deactivateUser(userId) {
      const variables = {
        userId: userId,
      };
      this.loading = true;

      this.$apollo
          .mutate({
            mutation: DEACTIVATE_USER,
            variables,
            update: (store, {data: {deactivateUser}, errors}) => {
              this.loading = false;
              if (errors) {
                return;
              }
              const data = store.readQuery({
                query: USERS_LIST,
              });
              const modifiedUser = data.users.find(
                  (u) => u.id === deactivateUser.id
              );
              modifiedUser.active = false;
              store.writeQuery({
                query: USERS_LIST,
                data,
              });
            },
          })
          .catch((error) => {
            this.hasErrors = true;
            this.loading = false;
            this.lastError = error;
          });
    },
  },
};
</script>
<style lang="stylus" scoped></style>
