<template>
  <v-app :dark="darkTheme" v-if="user">
    <v-navigation-drawer v-model="drawer" clipped fixed app>
      <v-list dense v-if="user.active">
        <v-list-item-group color="primary">
          <v-list-item v-for="(link, i) in links" :key="i" :to="link.path">
            <v-list-item-icon>
              <v-icon>
                {{ link.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ link.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app fixed clipped-left>
      <v-toolbar-items @click.stop="drawer = !drawer"></v-toolbar-items>
      <v-toolbar-title>Deploy Manager v2</v-toolbar-title>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-avatar
                  v-if="user"
                  :tile="false"
                  size="50"
                  color="grey lighten-4"
              >
                <img
                    :src="`https://ui-avatars.com/api/?rounded=true&name=-${user.name}`"
                    :alt="user.name"
                />
              </v-avatar>
            </v-btn>
          </template>
          <v-list v-if="isLoggedIn()">
            <v-list-item-group color="primary">
            <v-list-item @click="$router.push({name: 'changePassword'})">
              <v-list-item-title>Change password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <v-container fluid v-if="user.active">
        <slot />
      </v-container>
      <v-container fluid v-else>
        <v-alert :value="true" color="error" icon="fa-error">
          Your account is inactive. Contact the Administrator.
        </v-alert>
      </v-container>
    </v-main>
    <v-footer app fixed s>
      <v-spacer></v-spacer>
      <span>&copy; Bold 2020</span>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
import { onLogout } from "@/vue-apollo";
import { isLoggedIn } from "@/services/authService";
import CURRENT_USER from "../graphql/query/CurrentUser.gql";

export default {
  data: () => ({
    links: [
      {
        title: 'Releases',
        icon: 'mdi-calendar',
        path: '/release/list',
        routeName: 'releasesList'
      },
      {
        title: 'Services',
        icon: 'mdi-semantic-web',
        path: '/service/list',
        routeName: 'servicesList'
      },
      {
        title: 'Users',
        icon: 'mdi-account-supervisor-outline',
        path: '/users/list',
        routeName: 'usersList'
      }
    ],
    drawer: null,
    darkTheme: false,
  }),
  props: {
    source: String,
  },
  methods: {
    logout() {
      const apolloClient = this.$apollo.provider.defaultClient;
      onLogout(apolloClient);
      this.$router.push({ name: "login" });
    },
    isLoggedIn() {
      return isLoggedIn();
    },
  },
  apollo: {
    user: {
      query: CURRENT_USER,
    },
  },
};
</script>
