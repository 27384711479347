var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-default',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mb-3",attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Users "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.$apollo.loading,"headers":_vm.headers,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{attrs:{"active":item.selected}},[_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.id))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.email))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(item.active ? "Yes" : "No")+" ")]),_c('td',{staticClass:"text-xs-left"},[[_c('v-select',{attrs:{"disabled":item.id === _vm.user.id || _vm.user.role !== _vm.ADMIN_ROLE,"items":_vm.options,"item-text":"label","item-value":"value"},on:{"change":function($event){return _vm.setRole(item.id, $event)}},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}})]],2),_c('td',{staticClass:"text-xs-right"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',[(_vm.user.role === _vm.ADMIN_ROLE)?_c('v-list-item-title',{on:{"click":function($event){return _vm.$router.push({
                          name: 'setUserPassword',
                          params: { userId: item.id },
                        })}}},[_vm._v(" Change user password ")]):_vm._e()],1),(
                      item.active === true && item.id !== _vm.user.id
                    )?_c('v-list-item',[(_vm.user.role === _vm.ADMIN_ROLE)?_c('v-list-item-title',{on:{"click":function($event){return _vm.deactivateUser(item.id)}}},[_vm._v(" Deactivate ")]):_vm._e()],1):_vm._e(),(
                      item.active === false && item.id !== _vm.user.id
                    )?_c('v-list-item',[(_vm.user.role === _vm.ADMIN_ROLE)?_c('v-list-item-title',{on:{"click":function($event){return _vm.activateUser(item.id)}}},[_vm._v(" Activate ")]):_vm._e()],1):_vm._e()],1)],1)],1)],1)])]}}])}),(_vm.lastError)?_c('v-alert',{attrs:{"value":true,"color":"error","icon":"fa-error"}},[_vm._v(_vm._s(_vm.lastError)+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }